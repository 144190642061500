<template>
  <b-card no-body>
    <div class="m-2 d-flex justify-content-between table-actions">
      <div class="d-flex">
        <div class="mr-1">
          <b-button
            id="create-shortcut-button"
            variant="primary"
            :class="{
              'icon-disabled': !hasSystemSettingsActions,
            }"
            @click="hasSystemSettingsActions && toggleModal()"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Create New') }}</span>
          </b-button>
          <b-tooltip target="create-shortcut-button">
            {{ hasSystemSettingsActions ? $t('Create') : $t('no-action-permission') }}
          </b-tooltip>
        </div>
      </div>
    </div>
    <div class="m-2">
      <b-table-simple
        responsive
        class="fields__table"
      >
        <b-thead>
          <b-tr>
            <b-th
              v-for="(header, index) in tableColumns"
              :key="index"
              :class="header.class"
            >
              {{ $t(header.label) }}
            </b-th>
          </b-tr>
        </b-thead>
        <Draggable
          tag="b-tbody"
          :list="items"
          ghost-class="ghost"
          :animation="150"
          handle=".pz__drag-handle"
          @change="handleOrder"
        >
          <b-tr v-if="loading">
            <b-td
              colspan="100%"
              class="text-center"
            >
              <b-spinner class="align-middle" />
            </b-td>
          </b-tr>

          <b-tr v-else-if="!items.length">
            <b-td
              colspan="100%"
              class="text-center"
            >
              {{ $t('No matching records found') }}
            </b-td>
          </b-tr>

          <b-tr
            v-for="field in items"
            :key="field.shortcutUuid"
            :draggable="hasSystemSettingsActions"
            class="fields__row"
          >
            <b-td>
              <div class="row-flex-align-center">
                <ProzessDragHandle
                  v-if="hasSystemSettingsActions"
                  class="tw-mr-2"
                />

                <span class="line-clamp-1"> {{ field.title }} </span>
              </div>
            </b-td>
            <b-td>
              <span
                style="max-width: 300px"
                class="line-clamp-1"
              >{{ field.url }}</span>
            </b-td>

            <b-td class="text-nowrap">
              <div v-if="field.groupData">
                <template v-for="(group, groupIndex) in field.groupData">
                  <b-badge
                    v-if="groupIndex <= 1"
                    :key="groupIndex"
                    class="tw-mr-2"
                    pill
                    variant="secondary"
                  >
                    {{ $t(title(group.name)) }}
                  </b-badge>
                </template>

                <b-badge
                  v-if="field.groupData.length >= 3"
                  pill
                  variant="secondary"
                >
                  +{{ field.groupData.length - 2 }}
                </b-badge>
              </div>
            </b-td>

            <b-td class="row-flex-justify-center row-flex-items-center">
              <div :id="`toggle-${field.shortcutUuid}-toggle-switch`">
                <b-checkbox
                  v-model="field.visible"
                  :disabled="!hasSystemSettingsActions"
                  class="toggle-with-disable-class"
                  switch
                  @input="toggleVisible(field.shortcutUuid, field.visible)"
                />
              </div>

              <b-tooltip
                triggers="hover"
                placement="right"
                :title="$t(toggleTitle(field.visible))"
                :target="`toggle-${field.shortcutUuid}-toggle-switch`"
              />
              <feather-icon
                :id="`edit-row-${field.shortcutUuid}-preview-icon`"
                icon="EditIcon"
                size="16"
                :class="{
                  'icon-disabled': !hasSystemSettingsActions,
                }"
                class="mx-1 cursor-pointer"
                @click="hasSystemSettingsActions && edit(field.shortcutUuid)"
              />
              <b-tooltip
                :title="!hasSystemSettingsActions ? $t('no-action-permission') : $t('Edit')"
                :target="`edit-row-${field.shortcutUuid}-preview-icon`"
                placement="left"
              />
              <feather-icon
                :id="`delete-row-${field.shortcutUuid}-preview-icon`"
                icon="TrashIcon"
                size="16"
                :class="{
                  'icon-disabled': !hasSystemSettingsActions,
                }"
                class="mx-1 cursor-pointer"
                @click="hasSystemSettingsActions && remove(field.shortcutUuid)"
              />
              <b-tooltip
                :title="!hasSystemSettingsActions ? $t('no-action-permission') : $t('Delete')"
                :target="`delete-row-${field.shortcutUuid}-preview-icon`"
                placement="left"
              />
            </b-td>
          </b-tr>
        </Draggable>
      </b-table-simple>
    </div>
    <sidebar-form
      :visible="showForm"
      :viewing="viewing"
      :row-order="rowOrder"
      :resource-id="resourceId"
      @close="onCloseForm"
      @saved="fetchList"
    />
  </b-card>
</template>

<script>
import Draggable from 'vuedraggable'
import shortcutsService from '@/services/shortcuts'
import ProzessDragHandle from '@/components/shared/ProzessDragHandle.vue'
import SidebarForm from '@/views/pages/SystemSettings/Shortcut/Form.vue'
import { title } from '@/@core/utils/filter'
import { swalConfirmDeleteOption } from '@/helpers/app'
import { debounce } from 'lodash'

export default {
  components: { SidebarForm, ProzessDragHandle, Draggable },
  data() {
    return {
      title,
      loading: false,
      viewing: false,
      items: [],
      resourceId: null,
      showForm: false,
      toggleDisabled: false,
      tableColumns: [
        {
          key: 'title',
          label: 'Title',
          class: 'disabled',
        },
        {
          key: 'url',
          label: 'URL',
          class: 'disabled',
        },
        {
          key: 'groupData',
          label: 'Groups',
          class: 'disabled',
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'disabled tw-text-center',
        },
      ],
    }
  },

  computed: {
    rowOrder() {
      return this.items.length ? this.items.length + 1 : 1
    },

    payload() {
      return this.items.map((item, index) => ({
        shortcutUuid: item.shortcutUuid,
        rowOrder: index + 1,
      }))
    },
  },

  // watch: {
  //   showForm: {
  //     handler() {
  //       this.fetchList()
  //     },
  //   },
  // },

  mounted() {
    this.fetchList()
  },
  methods: {
    toggleModal() {
      this.showForm = !this.showForm
    },
    handleOrder({ moved }) {
      this.debounceHandleOrder(moved, this)
    },

    toggleTitle(visible) {
      if (this.hasSystemSettingsActions) {
        return visible ? 'Enable visibility' : 'Disable visibility'
      }

      return 'no-action-permission'
    },
    debounceHandleOrder: debounce(async (val, vm) => {
      // START

      // this is for single old row to new row base

      // const shortcutUuid = val.element.shortcutUuid
      // const { rowOrder } = vm.items.find(
      //   (item, index) => item.rowOrder === val.newIndex + 1,
      // )

      // const newOrderPayload = [{shortcutUuid, rowOrder}]

      // END

      await shortcutsService('shortcut').shortcutChangeOrdering(vm.payload)
    }, 200),
    async toggleVisible(id, val) {
      this.debounceToggleVisible(id, val, this)
    },

    debounceToggleVisible: debounce(async (id, val, vm) => {
      await shortcutsService('shortcut').toggleShortcutVisibility(id, val)
    }, 200),

    async deleteResource(id) {
      const { response } = await this.$async(shortcutsService('shortcut').destroy(id))

      this.fetchList()
    },

    remove(id) {
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(result => {
        if (result.value) {
          this.deleteResource(id)
        }
      })
    },

    async edit(id) {
      this.resourceId = id
      this.showForm = true
    },
    onCloseForm() {
      this.resourceId = ''
      this.toggleModal()
    },

    async fetchList() {
      this.loading = true
      const response = await this.$async(shortcutsService('shortcut').getAll())
      this.items = response.response.data
      this.loading = false
    },
  },
}
</script>

/* eslint-disable no-useless-escape */
import * as yup from 'yup'

const urlPattern = /(https?):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]/

export const shortcutSchema = yup.object().shape({
  title: yup.string().required().max(100, 'Max 100'),
  url: yup.string().required().matches(urlPattern, 'Invalid url, always include https:// at the start').max(500, 'Max 500'),
})

export default {
  shortcutSchema,
}

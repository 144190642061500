<template>
  <prozess-sidebar-modal-wrapper
    id="shortcut-form"
    :visible="visible"
    :editing="!!resourceId"
    :form-icon="formIcon"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    :is-view-only="viewing"
    @close="close"
    @submit="save"
  >
    <form
      ref="form"
      autocomplete="off"
      style="flex: 1"
      class="tw-p-4"
      @submit.prevent
    >
      <prozess-input
        v-model="form.title"
        :disabled="viewing"
        :placeholder="$t('Shortcut name')"
        icon="TypeIcon"
        required
        hint="Required"
        field="title"
        name="title"
        :error="$hasError('title')"
        @enter="save"
      />

      <ProzessIconSelector
        v-model="form.icon"
        :error="$hasError('icon')"
        :required="false"
        :clear-search-on-select="false"
        @enter="save"
      />

      <prozess-input
        v-model="form.url"
        hint="Required"
        :placeholder="$t('URL')"
        icon="GlobeIcon"
        field="webhookUrl"
        name="webhookUrl"
        :error="$hasError('url')"
        @enter="save"
      />
      <b-input-group class="input-group-merge form-sidebar__add-user tw-pb-4">
        <b-input-group-prepend is-text>
          <feather-icon icon="UserPlusIcon" />
        </b-input-group-prepend>
        <v-select
          v-model="selectedGroup"
          style="flex: 1"
          :placeholder="$t('Add Group')"
          :options="groups"
          :get-option-label="option => option.name"
          @input="selectGroup"
          @search="onSearchGroup"
          @search:blur="onBlurSearchGroup"
        >
          <span slot="no-options">
            <span v-if="searchGroupText.length">{{ $t(`No results found`) }}</span>
            <span v-else>{{ $t(`Search by name`) }}</span>
          </span>
        </v-select>
      </b-input-group>

      <div class="form-sidebar__users tw-pt-3 tw-mt-6 text-muted">
        <div class="p-1 form-sidebar__users-header">
          <h3>{{ $t('Groups') }}</h3>
        </div>
        <div class="form-sidebar__user-list">
          <div
            v-if="selectedGroups.length === 0"
            class="p-1"
          >
            {{ $t('No groups.') }}
          </div>
          <div
            v-for="group of selectedGroups"
            :key="group.groupUuid"
            class="form-sidebar__user p-1 d-flex justify-content-between"
          >
            <p>{{ group.name }}</p>
            <div
              class="form-sidebar__remove-user d-flex justify-content-center align-items-center"
              @click="removeGroup(group)"
            >
              <feather-icon
                icon="XIcon"
                class="tw-text-white"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import vSelect from 'vue-select'
import ProzessIconSelector from '@/components/shared/ProzessIconSelector.vue'
import { shortcutSchema } from '@/schema/shortcuts'
import groupService from '@/services/group'
import { debounce } from 'lodash'
import shortcutsService from '@/services/shortcuts'

const initialForm = {
  title: '',
  icon: '',
  url: '',
}

export default {
  components: { ProzessIconSelector, vSelect },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    viewing: {
      type: Boolean,
      default: false,
    },

    rowOrder: {
      type: Number,
      required: true,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      saving: false,
      form: this.$lodash.cloneDeep(initialForm),
      loading: false,
      errors: [],
      selectedGroups: [],
      cloneGroups: [],
      selectedGroup: '',
      searchGroupText: '',
      deletedGroups: [],
      visibleStatus: true,
      editRowOrder: '',
      groups: [],
    }
  },

  computed: {
    formIcon() {
      if (this.resourceId && this.viewing) return ''
      if (this.resourceId && !this.viewing) return 'EditIcon'
      return 'PlusIcon'
    },
    formTitle() {
      if (this.resourceId && this.viewing) return 'View Shortcut'
      if (this.resourceId && !this.viewing) return 'Edit Shortcut'
      return 'Add Shortcut'
    },

    deletedGroupUpdate() {
      return this.$lodash.differenceWith(this.cloneGroups, this.selectedGroups, this.$lodash.isEqual).map(group => group.groupUuid)
    },

    existingSelectedGroups() {
      return this.selectedGroups.filter(group => group.isExisting)
    },

    newGroups() {
      return this.selectedGroups.map(group => group.groupUuid)
    },
    updatedGroups() {
      return this.$lodash.differenceWith(this.selectedGroups, this.cloneGroups, this.$lodash.isEqual).map(group => group.groupUuid)
    },
  },

  watch: {
    visible: {
      handler(newVal) {
        if (this.resourceId) this.callApi(this.resourceId)
      },
      immediate: true,
    },
  },

  methods: {
    async callApi(id) {
      this.loading = true
      const { response } = await this.$async(shortcutsService('shortcut').getOne(id))

      const data = response?.data

      if (!data) return false
      const form = {
        icon: data.icon,
        title: data.title,
        url: data.url,
      }

      const groupData = data.groupData.map(group => ({
        ...group,
      }))
      this.visibleStatus = data.visible
      this.editRowOrder = data.rowOrder
      this.selectedGroups = this.$lodash.cloneDeep(groupData)
      this.cloneGroups = this.$lodash.cloneDeep(groupData)
      this.form = form
      this.loading = false
    },
    close() {
      this.resetForm()
      this.$emit('close')
    },

    async removeGroup(group) {
      this.selectedGroups = this.selectedGroups.filter(groupFilter => groupFilter.groupUuid !== group.groupUuid)
    },

    onSearchGroup(search, loading) {
      this.searchGroupText = search
      if (search.length) {
        loading(true)
        this.searchGroup(search, loading, this)
      }
    },

    onBlurSearchGroup() {
      this.groups = []
    },

    searchGroup: debounce(async (search, loading, vm) => {
      const res = await groupService.searchGroup(search)
      vm.groups = res.data
      loading(false)
    }, 500),

    async selectGroup(group) {
      delete group.email
      this.searchGroupText = ''
      this.selectedGroup = null
      const isExisting = this.selectedGroups.find(groupFind => groupFind.groupUuid === group.groupUuid)
      if (isExisting) return this.showWarning('Group already exist')
      this.selectedGroups.push(group)
    },
    resetForm() {
      this.errors = []
      this.form = this.$lodash.cloneDeep(initialForm)
      this.selectedGroups = []
      this.$forceUpdate()
    },

    async save() {
      this.errors = await this.yupValidate(shortcutSchema, this.form)
      if (this.errors.length) return false

      const form = {
        ...this.form,
        visible: this.visibleStatus ?? true,
        rowOrder: this.resourceId ? this.editRowOrder : this.rowOrder,
        newGroups: this.resourceId ? this.updatedGroups : this.newGroups,
        updatedGroups: [],
        deletedGroups: this.deletedGroupUpdate,
      }

      const { errors } = await this.$async(this.createOrUpdate(form))

      if (errors) return false

      this.$emit('saved')
      this.close()
    },

    async createOrUpdate(payload) {
      if (this.resourceId) {
        return shortcutsService('shortcut').patch(this.resourceId, payload)
      }

      return shortcutsService('shortcut').store(payload)
    },
  },
}
</script>

<style></style>
